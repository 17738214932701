import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ReferrerData } from 'entities/profile/referral-program';

type Data = ReferrerData;
type Params = undefined;

const NAME = '/Referral/GetLink';
const GetReferrerDataEndpoint = createModernEndpoint<Params, Data>(NAME);

export default GetReferrerDataEndpoint;
export { GetReferrerDataEndpoint };
