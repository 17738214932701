import Button, { ButtonProps } from '@swe/shared/ui-kit/components/button';
import Grid from '@swe/shared/ui-kit/components/grid';
import Loader from '@swe/shared/ui-kit/components/loader';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

import React, { ReactNode } from 'react';

import { ReferralProgramConditions } from 'domains/profile/containers/referral-program/components/referral-program-conditions';
import { ReferralProgram } from 'entities/profile/referral-program';

type ReferralProgramProps = {
  isLoading: boolean;
  referralProgram?: ReferralProgram;
  btnLeft?: Pick<ButtonProps, 'icon' | 'onClick'> & { children: string };
  btnRight?: Pick<ButtonProps, 'icon' | 'onClick' | 'children'> & { children: string };
  middleSection?: ReactNode;
};

const ReferralProgramCom = ({ isLoading, referralProgram, btnLeft, btnRight, middleSection }: ReferralProgramProps) => {
  if (isLoading) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }
  if (!referralProgram) {
    return null;
  }
  if (!referralProgram.enabled) {
    return (
      <Text
        size="xl"
        adaptive
      >
        The referral program is currently unavailable.
      </Text>
    );
  }

  return (
    <Stack
      spacing={{
        desktop: 'lg',
        tablet: 'lg',
        mobile: 'sm',
      }}
    >
      <ReferralProgramConditions referralProgram={referralProgram} />
      {middleSection}
      {(btnLeft || btnRight) && (
        <Grid.Row
          hAlign="end"
          vAlign="center"
        >
          {!!btnLeft && (
            <Grid.Cell cols={6}>
              <Button
                block
                color="light"
                icon={btnLeft.icon}
                onClick={btnLeft.onClick}
                name={btnLeft.children}
              >
                {btnLeft.children}
              </Button>
            </Grid.Cell>
          )}
          {!!btnRight && (
            <Grid.Cell cols={6}>
              <Button
                block
                color="light"
                icon={btnRight.icon}
                onClick={btnRight.onClick}
                name={btnRight.children}
              >
                {btnRight.children}
              </Button>
            </Grid.Cell>
          )}
        </Grid.Row>
      )}
    </Stack>
  );
};

export { ReferralProgramCom };
