import { SnackbarService } from '@swe/shared/providers/snackbar';
import { copy } from '@swe/shared/tools/clipboard';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Alert } from '@swe/shared/ui-kit/components/alert';
import { CopyIcon, GiftIcon, ShareIcon } from '@swe/shared/ui-kit/components/icon';
import Text from '@swe/shared/ui-kit/components/text';

import { share } from '@swe/shared/utils/share';

import React, { useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { useStoreInfo } from 'common/use-cases/use-store-info';
import { ReferralProgramCom } from 'domains/profile/components/referral-program';
import { useReferralProgram } from 'domains/profile/use-cases/use-referral-program';

const ReferralProgram = () => {
  const { referralProgram, referrerData, isLoading } = useReferralProgram();
  const { name: storeName } = useStoreInfo();
  const { desktop, mobile } = useBreakpoint();
  const referrerCode = referrerData?.code ?? '';

  const { isRegistrationComplete, redirectUnauthorized } = useCurrentUser();

  const navigate = useRouterNavigate();

  const textToShare = useMemo(() => {
    if (referrerData?.text) {
      return referrerData.text;
    }
    const referrerLink = referrerData?.url ?? '';

    return `Hey! Use my link (${referrerLink}) to sign up and get promo codes in the ${storeName} or use the code ${referrerCode} if you are using the iOS app.`;
  }, [referrerData, storeName, referrerCode]);

  const copyCodeToClipboard = useCallback(() => {
    copy(referrerCode);
    SnackbarService.push({
      type: 'success',
      message: 'Code copied to clipboard',
      autoHideDuration: 1000,
    });
  }, [referrerCode]);

  const handleCopyLink = useCallback(async () => {
    const sharedData = { text: textToShare };

    if (desktop || !navigator.canShare(sharedData)) {
      copy(textToShare);
      SnackbarService.push({
        type: 'success',
        message: 'Link copied to clipboard',
        autoHideDuration: 1000,
      });
    } else {
      await share({ text: textToShare });
    }
  }, [desktop, textToShare]);

  const userPops = useMemo(() => {
    const middleSection = (
      <Text
        size={mobile ? 'lg' : 'xl'}
        className={styles.text}
      >
        Send a code to register in a mobile application or share a link to register through the site
      </Text>
    );
    return {
      middleSection,
      ...(referrerData
        ? {
            btnLeft: {
              icon: CopyIcon,
              onClick: copyCodeToClipboard,
              children: 'Copy code',
            },
            btnRight: {
              icon: ShareIcon,
              onClick: handleCopyLink,
              children: 'Share link',
            },
          }
        : {}),
    };
  }, [copyCodeToClipboard, handleCopyLink, mobile, referrerData]);

  const unRegUserPops = useMemo(() => {
    const middleSection = (
      <Alert
        color="success"
        icon={GiftIcon}
      >
        To benefit from our referral program log in or create an account. Start earning bonuses with your friends or
        family!
      </Alert>
    );
    return {
      middleSection,
      btnLeft: {
        onClick: () => navigate(Routes.SignUp),
        children: 'Sign Up',
      },
      btnRight: {
        onClick: () => redirectUnauthorized(),
        children: 'Log In',
      },
    };
  }, [redirectUnauthorized, navigate]);

  return (
    <ReferralProgramCom
      referralProgram={referralProgram}
      isLoading={isLoading}
      {...(isRegistrationComplete ? userPops : unRegUserPops)}
    />
  );
};

export { ReferralProgram };
