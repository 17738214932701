const share = async (data: ShareData): Promise<boolean> => {
  if (navigator.share && navigator.canShare(data)) {
    try {
      await navigator.share(data);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export { share };
