import { useCurrentUser } from 'common/providers/user';
import GetReferralProgramEndpoint from 'endpoints/profile/referral-program/get-referral-program';
import GetReferrerDataEndpoint from 'endpoints/profile/referral-program/get-referrer-data';

const useReferralProgram = () => {
  const { isRegistrationComplete } = useCurrentUser() ?? {};
  const params = isRegistrationComplete ? undefined : null;
  const { data: referralProgramData, isLoading: isLoadingReferralProgram } =
    GetReferralProgramEndpoint.useRequest(undefined);
  const { data: referrerData, isLoading: isLoadingReferrerData } = GetReferrerDataEndpoint.useRequest(params);

  return {
    referralProgram: referralProgramData,
    referrerData,
    isLoading: isLoadingReferralProgram || isLoadingReferrerData,
  };
};

export { useReferralProgram };
