import Link from '@swe/shared/providers/router/link';
import { useBreakpoint } from '@swe/shared/tools/media';
import Grid from '@swe/shared/ui-kit/components/grid';
import { ReferralIllustration } from '@swe/shared/ui-kit/components/illustration';
import Stack from '@swe/shared/ui-kit/components/stack';

import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatIntegerPrice } from '@swe/shared/utils/price';
import cl from 'clsx';

import React, { useMemo } from 'react';

import styles from './styles.module.scss';

import { Routes } from 'common/router/constants';
import { getDiscountAmountLabel, ReferralProgram } from 'entities/profile/referral-program';

type ReferralProgramConditionsProps = ComponentHasClassName & {
  referralProgram: ReferralProgram;
};

const ReferralProgramConditions = ({ className, referralProgram }: ReferralProgramConditionsProps) => {
  const { mobile } = useBreakpoint();
  const {
    referralDiscountAmount,
    referralDiscountType,
    referralMinInvoiceCount,
    referralMinInvoiceAmount,
    referrerDiscountAmount,
    referrerDiscountType,
  } = referralProgram;
  const needPlacedReferralOrders = referralMinInvoiceCount || 1;

  const referralDiscountLabel = useMemo(
    () => getDiscountAmountLabel(referralDiscountType, referralDiscountAmount),
    [referralDiscountAmount, referralDiscountType],
  );

  const referrerDiscountLabel = useMemo(
    () => getDiscountAmountLabel(referrerDiscountType, referrerDiscountAmount),
    [referrerDiscountAmount, referrerDiscountType],
  );

  return (
    <Grid.Row
      className={cl(styles.root, className)}
      vAlign="center"
      spacing={{
        desktop: 'lg',
        tablet: 'lg',
        mobile: 'sm',
      }}
      wrap={mobile}
    >
      <Grid.Cell
        cols={{
          mobile: 12,
          tablet: 'content',
          desktop: 'content',
        }}
      >
        <ReferralIllustration />
      </Grid.Cell>
      <Grid.Cell
        cols={{
          mobile: 12,
          tablet: 'auto',
          desktop: 'auto',
        }}
      >
        <Stack>
          <Text
            variant="headline"
            size="md"
          >
            {referrerDiscountLabel} discount for you, <br />
            {referralDiscountLabel} discount for your referees
          </Text>
          <Stack>
            <Text
              variant="body"
              size="xl"
            >
              {`When a referral signed up and placed ${needPlacedReferralOrders} order(s)`}
              {referralMinInvoiceAmount > 0 && ` above ${formatIntegerPrice(referralMinInvoiceAmount)}`}
            </Text>
            <Link href={Routes.AgreementsReferrerTermsOfUse}>
              <Text
                variant="body"
                size="xl"
              >
                Terms of Use
              </Text>
            </Link>
          </Stack>
        </Stack>
      </Grid.Cell>
    </Grid.Row>
  );
};

export { ReferralProgramConditions };
export default ReferralProgramConditions;
