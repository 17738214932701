import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { useCurrentUser } from 'common/providers/user';
import { Routes } from 'common/router/constants';
import { ReferralProgram } from 'domains/profile/containers/referral-program';

const TITLE = 'Invite Friends';

const ReferralProgramPage: ApplicationPage = () => {
  const { isRegistrationComplete } = useCurrentUser();

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: TITLE,
        hideBackButton: !isRegistrationComplete,
        defaultBackRoute: Routes.Profile,
      }}
    >
      <ReferralProgram />
    </Page>
  );
};

ReferralProgramPage.getMeta = () => ({ title: TITLE });

ReferralProgramPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ReferralProgramPage;
